import * as basicLightbox from "basiclightbox";

class YoutubePlayer {
    constructor(selector) {
        this.selector = selector;
        this._startPlayer = this._startPlayer.bind(this);
        this.selector.addEventListener('click', this._startPlayer);
    }

    _startPlayer(e) {
        e.preventDefault();

        this.video_id = this.selector.getAttribute('data-video-id');

        if (this.video_id !== '') {
            let w = window.innerWidth;
            let h = (w / 16) * 9;
            let modify = 0.8;

            if (window.innerWidth <= 600) {
                w = window.innerWidth;
                h = (w / 16) * 9;
                modify = 1;
            }

            const div = document.createElement('div');
            const modal = document.createElement('div');
            const close = document.createElement('a');
            close.classList.add('modal__close');
            close.innerHTML = '×';

            modal.classList.add('modal');
            modal.classList.add('modal--movie')
            modal.setAttribute('data-element', 'modal');

            const video = document.createElement('iframe');
            video.setAttribute('src', 'https://www.youtube.com/embed/' + this.video_id + '?rel=0&autoplay=1');
            video.setAttribute('width', w * modify);
            video.setAttribute('height', h * modify);
            video.setAttribute('frameborder', 0);

            modal.appendChild(close);
            modal.appendChild(video);
            div.appendChild(modal);

            let instance = basicLightbox.create(div);
            instance.show();

            close.addEventListener('click', (e) => {
                instance.close();
                instance.innerHTML = '';
            });
        }
    }
}
export default YoutubePlayer;
class Faq {
    constructor(selector) {
        this.selector = selector;
        this.items = this.selector.querySelectorAll('[data-element="item"]');

        if (this.items !== null) {
            this.items.forEach(item => {
                let clicker = item.querySelector('[data-element="clicker"]');
                if (clicker !== null) {
                    clicker.addEventListener('click', this._clickerEvent);
                }
            });
        }
    }
    _clickerEvent(e) {
        e.preventDefault();
        let item = e.target;
        let parent = item.parentNode;

        console.log(item.tagName);
        if (item.tagName === 'SPAN') {
            parent = parent.parentNode;
        }
        console.log(item, parent);
        let content = null;
        if (parent !== null) {
            content = parent.querySelector('[data-element="content"]');
            if (parent.tagName === 'SPAN') {
                content = parent.parentNode.querySelector('[data-element="content"]');
                // console.log(content);
            }
            if (content !== null) {
                if (content.classList.contains('faq__item-content--active')) {
                    content.classList.remove('faq__item-content--active');
                    if (item.tagName === 'A')
                        e.target.querySelector('span').innerText = '+';
                    else e.target.innerText = '+';
                } else {
                    content.classList.add('faq__item-content--active');
                    if (item.tagName === 'A')
                        e.target.querySelector('span').innerText = '-';
                    else e.target.innerText = '-';
                }
            }
        }
    }
}
export default Faq;
class SpecRange {
    constructor(selector) {
        this.selector = selector;
        this.ranges = this.selector.querySelectorAll('[data-range]');

        if (this.ranges !== null) {
            this.ranges.forEach(range => {
                let button = range.querySelector('[data-button]');
                if (button !== null) {
                    button.addEventListener('click', e => {
                        e.preventDefault();

                        let range_id = range.getAttribute('data-id');
                        console.log(range_id);
                        if (range.classList.contains('spec-range__single--active')) {
                            this._clearBlock();
                            button.innerText = 'Rozwiń';
                            range.classList.remove('spec-range__single--active');

                            if (range_id == 1 || range_id == 3) {
                                button.classList.remove('link--arrow-left');
                            } else if (range_id == 2 || range_id == 4) {
                                button.classList.add('link--arrow-left');
                            }

                        } else {
                            this._clearBlock();
                            button.innerText = 'Zwiń';
                            range.classList.add('spec-range__single--active');

                            if (range_id == 1) {
                                this.selector.querySelector('[data-id="2"]').classList.add('spec-range__single--hide');
                            }

                            if (range_id == 1 || range_id == 3) {
                                button.classList.add('link--arrow-left');
                            } else {
                                button.classList.remove('link--arrow-left');
                            }

                            if (range_id == 1) {

                            } else if (range_id == 2) {
                                this.selector.querySelector('[data-id="1"]').classList.add('spec-range__single--hide');
                            } else if (range_id == 3) {
                                this.selector.querySelector('[data-id="4"]').classList.add('spec-range__single--hide');
                            } else if (range_id == 4) {
                                this.selector.querySelector('[data-id="3"]').classList.add('spec-range__single--hide');
                            }
                        }
                    });
                }
            });
        }
    }
    _clearBlock() {
        this.ranges.forEach(range => {
            if (range.classList.contains('spec-range__single--active')) {
                range.classList.remove('spec-range__single--active');
            }
            if (range.classList.contains('spec-range__single--hide')) {
                range.classList.remove('spec-range__single--hide');
            }
        });
    }
}
export default SpecRange;
class ResidentMap {
    constructor(selector) {
        this.selector = selector;
        this.map_container = this.selector.querySelector('[data-element="map-container"]');
        this.points = this.selector.querySelectorAll('[data-element="map-point"]');
        this.content = this.selector.querySelector('[data-element="content"]');
        this.thumbnail = this.content.querySelector('[data-element="thumbnail"]');
        this.video = this.content.querySelector('[data-element="video"]');
        this.placeholder = this.content.querySelector('[data-element="placeholder"]');
        this.inMobile = false;
        this.timer = null;
        this.currentPoint = null;
        this.prevButton = this.selector.querySelector('[data-element="content-container"]').querySelector('[data-element="button-prev"]');
        this.nextButton = this.selector.querySelector('[data-element="content-container"]').querySelector('[data-element="button-next"]');

        if (window.innerWidth <= 640) {
            this.isMobile = true;
        }

        this._nextButton = this._nextButton.bind(this);
        this._prevButton = this._prevButton.bind(this);

        this.prevButton.addEventListener('click', this._prevButton);
        this.nextButton.addEventListener('click', this._nextButton);

        if (this.points.length > 0) {
            this.points.forEach(point => {
                if (point.classList.contains('world-map__point--active')) {
                    let video_id = point.getAttribute('data-video-id');
                    let title = point.getAttribute('data-title');

                    if (video_id === '') {
                        this.placeholder.classList.add('world-map__no-video--active');
                        this.video.classList.remove('video--active');
                    } else {
                        this.video.setAttribute('data-video-id', '');
                        this.video.setAttribute('data-video-id', video_id);

                        this.thumbnail.setAttribute('src', '');
                        this.thumbnail.setAttribute('alt', '');

                        this.thumbnail.setAttribute('src', 'https://img.youtube.com/vi/' + video_id + '/maxresdefault.jpg');
                        this.thumbnail.setAttribute('alt', title);

                        this.placeholder.classList.remove('world-map__no-video--active');
                        this.video.classList.add('video--active');
                    }

                    this.currentPoint = point.getAttribute('data-index');
                }
                this._pointClick = this._pointClick.bind(this);

                point.addEventListener('click', this._pointClick);
            });
        }
        this._autoPlayInit();
    }
    _pointClick(e) {
        e.preventDefault();

        let point = e.target.closest('a');

        this._removeActiveFromAllPoints();

        point.classList.add('world-map__point--active');

        let video_id = point.getAttribute('data-video-id');
        let title = point.getAttribute('data-title');

        if (video_id === '') {
            this.placeholder.classList.add('world-map__no-video--active');
            this.video.classList.remove('video--active');
        } else {
            this.video.setAttribute('data-video-id', '');
            this.video.setAttribute('data-video-id', video_id);

            this.thumbnail.setAttribute('src', '');
            this.thumbnail.setAttribute('alt', '');

            this.thumbnail.setAttribute('src', 'https://img.youtube.com/vi/' + video_id + '/maxresdefault.jpg');
            this.thumbnail.setAttribute('alt', title);

            this.placeholder.classList.remove('world-map__no-video--active');
            this.video.classList.add('video--active');
        }

        this.currentPoint = point.getAttribute('data-index');

        clearInterval(this.timer);
        this._autoPlayInit();
        // if (this.isMobile) {
        //     window.scrollTo({
        //         top: this.content.getBoundingClientRect().top + window.pageYOffset - 200
        //     });
        // }
    };
    _autoPlayInit() {
        this.timer = setInterval(() => {
            // console.log(this.currentPoint);
            let nextPoint = parseInt(this.currentPoint) +1;
            if (nextPoint > (this.points.length-1)) {
                nextPoint = 0;
            }
            this._setActivePoint(nextPoint);
        }, 5000);
    }
    _setActivePoint(index) {
        console.log(index);
        let point = this.map_container.querySelector('[data-index="' + index + '"]');

        this._removeActiveFromAllPoints();

        point.classList.add('world-map__point--active');

        let video_id = point.getAttribute('data-video-id');
        let title = point.getAttribute('data-title');

        if (video_id === '') {
            this.placeholder.classList.add('world-map__no-video--active');
            this.video.classList.remove('video--active');
        } else {
            this.video.setAttribute('data-video-id', '');
            this.video.setAttribute('data-video-id', video_id);

            this.thumbnail.setAttribute('src', '');
            this.thumbnail.setAttribute('alt', '');

            this.thumbnail.setAttribute('src', 'https://img.youtube.com/vi/' + video_id + '/maxresdefault.jpg');
            this.thumbnail.setAttribute('alt', title);

            this.placeholder.classList.remove('world-map__no-video--active');
            this.video.classList.add('video--active');
        }

        this.currentPoint = index;

        clearInterval(this.timer);
        this._autoPlayInit();
        // console.log(this.currentPoint);

        // if (this.isMobile) {
        //     window.scrollTo({
        //         top: this.content.getBoundingClientRect().top + window.pageYOffset - 200
        //     });
        // }
    }
    _nextButton(e) {
        e.preventDefault();

        let nextPoint = parseInt(this.currentPoint) + 1;
        if (nextPoint > (this.points.length - 1)) nextPoint = 0;

        this._setActivePoint(nextPoint);
    }

    _prevButton(e) {
        e.preventDefault();

        let prevPoint = this.currentPoint-1;
        if (prevPoint < 0) prevPoint = (this.points.length-1);

        this._setActivePoint(prevPoint);
    }
    _removeActiveFromAllPoints() {
        this.points.forEach(p => {
            if (p.classList.contains('world-map__point--active')) {
                p.classList.remove('world-map__point--active');
            }
        });
    }
}
export default ResidentMap;
class Archive {
    constructor(selector) {
        this.selector = selector;

        let year_clickers = this.selector.querySelectorAll('[data-clicker="year"]');
        let month_clickers = this.selector.querySelectorAll('[data-clicker="month"]');

        if (year_clickers !== null) {
            year_clickers.forEach(clicker => {
                console.log(clicker);
                clicker.addEventListener('click', e => {
                    e.preventDefault();

                    let parent = clicker.parentNode;
                    console.log(parent);
                    if (parent.classList.contains('archives-block__item--active')) {
                        parent.classList.remove('archives-block__item--active');
                    } else {
                        parent.classList.add('archives-block__item--active');
                    }
                });
            });
        }

        if (month_clickers !== null) {
            month_clickers.forEach(clicker => {
                console.log(clicker);
                clicker.addEventListener('click', e => {
                    e.preventDefault();

                    let parent = clicker.parentNode;
                    if (parent.classList.contains('archives-block__submenu-item--active')) {
                        parent.classList.remove('archives-block__submenu-item--active');
                    } else {
                        parent.classList.add('archives-block__submenu-item--active');
                    }
                });
            });
        }
    }
}
export default Archive;
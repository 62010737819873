class Opinions {
    constructor(selector) {
        this.selector = selector;
        this.opinions = this.selector.querySelectorAll('[data-opinion]');

        if (this.opinions.length > 0) {
            this.opinions.forEach(op => {
                let button = op.querySelector('[data-button]');

                if (button !== null) {
                    button.addEventListener('click', e => {
                        e.preventDefault();

                        let hc = op.querySelector('[data-hidden-content]');
                        if (hc !== null) {
                            if (!hc.classList.contains('opinion__hidden-content--shown')) {
                                hc.classList.add('opinion__hidden-content--shown');

                                button.classList.add('opinion__button--active');
                                button.innerText = 'Zwiń';
                            } else {
                                hc.classList.remove('opinion__hidden-content--shown');
                                button.classList.remove('opinion__button--active');
                                button.innerText = 'Rozwiń';
                            }
                        }
                    });
                }
            });
        }
    }
}
export default Opinions;
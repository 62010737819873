class Podcasts {
    constructor(selector) {
        this.selector = selector;

        this.postContainer = this.selector.querySelector('[data-container]');
        this.loader = this.selector.querySelector('[data-loader]');
        this.categories = this.selector.querySelector('[data-categories]');
        this.load_more = this.selector.querySelector('[data-load-more]');
        this.category = null;
        this.page = 1;

        this.cache = {};

        this.isLoading = false;
        this.lastPage = false;

        this._loadAttributes();

        if (this.loadOnInit) {
            this.postContainer.innerHTML = '';
            this._loadPosts();
        }

        if (this.load_more !== null) {
            this._nextPageEvent = this._nextPageEvent.bind(this);
            this.load_more.addEventListener('click', this._nextPageEvent);
        }

        if (this.categories !== null) {
            let items = this.categories.querySelectorAll('[data-element="category"]');
            if (items !== null) {
                items.forEach(item => {
                    this._clickCategory = this._clickCategory.bind(this);
                    item.addEventListener('click', this._clickCategory);
                });
            }
        }
    }

    _clickCategory(e) {
        e.preventDefault();

        let value = e.target.getAttribute('data-value');
        if (value !== '') {
            if (value == '-1') {
                this.category = null;
            } else {
                this.category = value;
            }
            this.page = 1;
            this.postContainer.innerHTML = '';
            this._loadPosts();
        }
    }

    _loadAttributes(){
        let types = this.selector.getAttribute('data-type');
        if(types !== null && types !== '') {
            this.type = types;
        }

        let perPage = this.selector.getAttribute('data-per-page');
        if(perPage !== null && perPage !== ''){
            this.perPage = parseInt(perPage);
        } else this.perPage = 10;

        if (window.innerWidth <= 1199) {
            this.perPage = 4;
        }

        let loadOnInit = this.selector.getAttribute('data-load-on-init');
        if(loadOnInit !== null && loadOnInit !== ''){
            this.loadOnInit = (loadOnInit === 'true');
        } else this.loadOnInit = false;

        let max = this.selector.getAttribute('data-max');
        if (max !== null && max !== '') {
            this.max = max;
        }
    }

    _nextPageEvent(event){
        event.preventDefault();
        if(!this.lastPage) {
            this._nextPage();
        }
    }

    _nextPage(){
        this.page++;
        this._loadPosts();
    }

    _getFromAPI(){
        let queryString = this._buildQuery();
        let url = '/wp-json/wp/v2/podcast?' + queryString;
        return fetch(url, {
            method: 'GET',
            cache: 'no-cache',
            credentials: "same-origin",
            redirect: "follow",
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (!response.ok) {
                return response.json().then(function(response) {
                    throw Error(response.code);
                })
            } else {
                return response.json();
            }
        });
    }

    _buildQuery(next = false){
        let query = [
            'per_page=' + this.perPage,
            'page=' + (next ? this.page+1 : this.page)
        ];

        if(this.category !== null){
            query.push('podcast_cat=' + this.category);
        }

        query.push('_embed');

        return query.join('&');
    }

    _loadPosts() {
        this._showLoaderAnimation();
        this.isLoading = true;
        let key = this.page + '_' + this.category;
        if (this.cache.hasOwnProperty(key)) {
            if (this.cache[key] !== null) {
                this._generatePosts(this.cache[key]);
                let new_page = this.page+1;
                let new_key = new_page + '_' + this.category;
                if (this.cache.hasOwnProperty(new_key)) {
                    if (this.cache[new_key] !== null) {
                        this._showLoadMoreButton();
                    } else {
                        this._hideLoadMoreButton();
                    }
                } else {
                    this._getAPINextPage().then(next_response => {
                        if (next_response.length > 0) {
                            this.cache[new_key] = next_response;
                            this._showLoadMoreButton();
                        }
                    }).catch(error => {
                        console.log('1 ' + error);
                        this._hideLoadMoreButton();
                        this.cache[new_key] = null;
                        if(error.message != 'rest_post_invalid_page_number') this._handleError(error);
                    });
                }
            }
        } else {
            this._getFromAPI().then(response => {
                if(response.length > 0){
                    this.cache[key] = response;
                    this._generatePosts(response);

                    this._hideLoaderAnimation();
                    let new_page = this.page+1;
                    let new_key = new_page + '_' + this.category;
                    this._getAPINextPage().then(next_response => {
                        if (next_response.length > 0) {
                            this.cache[new_key] = next_response;
                            this._showLoadMoreButton();
                        }
                    }).catch(error => {
                        console.log('2 ' + error);
                        this._hideLoadMoreButton();
                        this.cache[new_key] = null;
                        if(error.message != 'rest_post_invalid_page_number') this._handleError(error);
                    });
                } else {
                    this._noResults();
                }
            }).catch(error => {
                this._handleError(error);
            })
        }
    }
    _getAPINextPage() {
        let queryString = this._buildQuery(true);
        let url = '/wp-json/wp/v2/podcast?' + queryString;
        return fetch(url, {
            method: 'GET',
            cache: 'no-cache',
            credentials: "same-origin",
            redirect: "follow",
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(response => {
            if (!response.ok) {
                return response.json().then(function(response) {
                    throw Error(response.code);
                })
            } else {
                return response.json();
            }
        });
    }
    _generatePosts(posts) {
        if (this.postContainer.classList.contains('podcast-list__container--not-found')) {
            this.postContainer.classList.remove('podcast-list__container--not-found');
        }

        posts.forEach((post, key) => {
            let postElement = this._generatePodcastBlock(post);
            this.postContainer.append(postElement);
        });

        this._hideLoaderAnimation();
        this.isLoading = false;
    }
    _showLoaderAnimation() {
        this.loader.classList.add('loader--shown');
    }
    _hideLoaderAnimation() {
        this.loader.classList.remove('loader--shown');
    }
    _noResults(){
        if(this.page === 1){
            let text = document.createElement('p');
            text.innerHTML = 'Brak podcastów spełniających kryteria.';
            this.postContainer.classList.add('podcast-list__container--not-found');
            this.postContainer.appendChild(text);
            this._hideLoaderAnimation();
        } else {
            this.lastPage = true;
            this._hideLoaderAnimation();
            this._hideLoadMoreButton();
        }
    }
    _handleError(error){
        console.log(error);
        if (error.code === 'rest_post_invalid_page_number') {
            this.lastPage = true;
            this._hideLoadMoreButton();
            this._hideLoaderAnimation();
        } else {
            let text = document.createElement('p');
            text.innerHTML = 'Wystąpił błąd poczas ładowania listy podcastów.';
            this.postContainer.classList.add('podcast-list__container--not-found');
            this.postContainer.appendChild(text);
            this._hideLoaderAnimation();
            this.isLoading = false;
        }
    }
    _generatePodcastBlock(post) {
        // console.log(post);

        let item = document.createElement('div');
        item.classList.add('podcast-list__single');

        let thumbnail_cntr = document.createElement('a');
        thumbnail_cntr.classList.add('podcast-list__single-image-cntr');
        thumbnail_cntr.setAttribute('href', post.link);

        let thumbnail = null;

        let thumbnail_url = null;
        if (typeof post._embedded["wp:featuredmedia"] !== 'undefined') {
            let media = post._embedded["wp:featuredmedia"][0];
            if (typeof media.media_details !== 'undefined') {
                if (typeof media.media_details.sizes["movie-poster"] !== 'undefined') {
                    thumbnail_url = media.media_details.sizes["movie-poster"].source_url;
                } else thumbnail_url = media.source_url;
            }
        }

        if (thumbnail_url !== null) {
            thumbnail = document.createElement('img');
            thumbnail.setAttribute('alt', post.title.rendered);
            thumbnail.setAttribute('src', thumbnail_url);
        }

        if (thumbnail !== null) {
            thumbnail_cntr.appendChild(thumbnail);
        }

        let data = document.createElement('div');
        data.classList.add('podcast-list__single-data');

        let date = document.createElement('div');
        date.classList.add('podcast-list__single-date');
        date.innerText = post.nt_date;

        data.appendChild(date);

        let time = document.createElement('div');
        time.classList.add('podcast-list__single-time');
        time.innerText = post.meta._nt_podcast_time;

        data.appendChild(time);

        let title = document.createElement('h3');
        title.classList.add('title', 'title--size-24');

        let title_link = document.createElement('a');
        title_link.setAttribute('href', post.link);
        title_link.innerHTML = post.title.rendered;

        title.appendChild(title_link);

        let link = document.createElement('a');
        link.setAttribute('href', post.link);
        link.classList.add('link', 'link--arrow');
        link.innerText = 'Więcej'; // zmienić na tłumaczone dynamicznie

        item.appendChild(thumbnail_cntr);
        item.appendChild(data);
        item.appendChild(title);
        item.appendChild(link);

        return item;
    }

    _hideLoadMoreButton() {
        this.load_more.style.display = 'none';
    }
    _showLoadMoreButton() {
        this.load_more.style.display = 'inline-block';
    }
}
export default Podcasts;
/**
 *  Created by Michał on 11.05.2017.
 */
import Glide from "@glidejs/glide/dist/glide.esm";
import WorldMap from "./../worldMap";
import SimpleLightbox from "simple-lightbox/dist/simpleLightbox.min";
import map from './../map';
import Opinions from "./../opinions";
import { Form } from '@netivo/base-scripts';
import Books from "./../books";
import SpecRange from "./../specRange";
import Archive from "./../archive";
import Ebook from "./../ebook";
import YoutubePlayer from "./../youtubePlayer";
import Faq from "./../faq";
import ResidentMap from "./../residentMap";
import Header from "./../header";
import InteractiveSpecs from "./../interactiveSpecs";
import Podcasts from "./../podcasts";
import QuantityInput from "./../quantityInput";
import UpdateCart from "./../updateCart";

let images = document.querySelectorAll('img');
if (images !== null) {
    images.forEach(image => {
        console.log();
        if (!image.classList.contains('slider__image') && image.getAttribute('loading') === null) {
            image.setAttribute('loading', 'lazy');
        }
    });
}

window.initMap = map;

function detectmob() {
    return ( navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)
    );
}
function toggleMenu(){
    let menu = document.querySelector('#mobile-menu');
    let mask = document.querySelector('#mask');
    let wrapper = document.querySelector('#wrapper');
    let content = document.querySelector('#content');
    document.querySelector('.js-hamburger').classList.toggle("active");
    // document.querySelector('#mask').toggle();
    menu.classList.toggle("toggled");
    mask.classList.toggle('shown');
    content.classList.toggle("toggled");
    if(menu.classList.contains("toggled")) {
        // wrapper.css('height', menu.height() + 'px');
        document.body.style.overflow = 'hidden';
        document.querySelector('html').style.overflow = 'hidden';
    }
    else {
        // wrapper.css('height', 'auto');
        document.body.style.overflow = 'auto';
        document.querySelector('html').style.overflow = 'auto';
    }
}

let mask = document.querySelector('#mask');
if (mask !== null) {
    let menu = document.querySelector('#mobile-menu');
    mask.addEventListener('click', (e) => {
        mask.classList.toggle('shown');
        menu.classList.toggle('toggled');
        if(!menu.classList.contains("toggled")) {
            document.body.style.overflow = 'auto';
            document.querySelector('html').style.overflow = 'auto';
        }
    });
}

let hamburger = document.querySelector('.js-hamburger');
if(hamburger !== null){
    hamburger.addEventListener('click', event => {
        event.preventDefault();
        toggleMenu();
    });
}

let mobile_close = document.querySelector('.js-mobile-close');
if (mobile_close != null) {
    mobile_close.addEventListener('click', function(e) {
        let menu = document.querySelector('#mobile-menu');
        if (menu != null && menu.classList.contains('toggled')) {
            let mask = document.querySelector('#mask');

            e.preventDefault();
            document.body.style.overflow = 'initial';
            document.querySelector('html').style.overflow = 'initial';
            menu.classList.remove('toggled');
            mask.classList.remove('shown');
            document.querySelector('.js-hamburger').classList.toggle("active");
        }
    });
}

window.addEventListener('DOMContentLoaded', () => {
    let lazyImages = [].slice.call(document.querySelectorAll(".lazy-loaded-image.lazy"));

    let lazyImageObserver = new IntersectionObserver(function(entries, observer) {
        entries.forEach(function(entry) {
            if (entry.isIntersecting) {
                let lazyImage = entry.target;
                lazyImage.src = lazyImage.dataset.src;
                lazyImage.classList.remove('lazy');
                lazyImageObserver.unobserve(lazyImage);
            }
        });
    });

    let header = document.querySelector('.js-header');
    if (header !== null) {
        new Header(header);
    }

    setTimeout(() => {
        let interactive_elements = document.querySelectorAll('.interactive-element');

        let window_width = window.innerWidth;
        let window_height = window.innerHeight;
        let window_top = document.body.scrollTop - 100;
        if (document.body.clientWidth <= 600) {
            window_top = document.body.scrollTop - 50;
        }
        let window_bottom = (window_top + window_height);

        for (const inter_element of interactive_elements) {
            let element_height = inter_element.clientHeight;
            let element_top = document.body.scrollTop + inter_element.getBoundingClientRect().top;
            let element_bottom = (element_top + element_height) - 20;

            if ((element_bottom >= window_top) && element_top <= window_bottom) {
                inter_element.classList.add('visible');
            }
        }
    }, 100);

    let gcrsl = null;
    let gallery_carousel = document.querySelector('.js-gallery-carousel');
    if (gallery_carousel !== null) {
        gcrsl = new Glide(gallery_carousel, {
            type: 'carousel',
            perView: 3,
            autoplay: 5000,
            animationDuration: 500,
            gap: 40,
            //clones: false,
            animationTimingFunc: 'linear',
            //bound: true,
            breakpoints: {
                400: {
                    autoplay: 4500,
                    perView: 2,
                },
            }
        }).mount();
    }
    let sccrsl = null;
    let shop_carousel = document.querySelector('.js-shop-carousel');
    if (shop_carousel !== null) {
        sccrsl = new Glide(shop_carousel, {
            type: 'slider',
            perView: 1,
            autoplay: 5000,
            animationDuration: 500,
            gap: 0,
            //clones: false,
            animationTimingFunc: 'linear',
            //bound: true
        }).mount();
    }

    let tu_glide = null;
    let trust_us = document.querySelector('.js-trust-us');
    if (trust_us !== null) {
        let prev_button = trust_us.parentNode.querySelector('[data-element="button-prev"]');
        let next_button = trust_us.parentNode.querySelector('[data-element="button-next"]');
        tu_glide = new Glide(trust_us, {
            type: 'slider',
            perView: 1,
            autoplay: 5000,
            animationDuration: 500,
            gap: 40,
            animationTimingFunc: 'linear',
            bound: true
        }).mount();

        prev_button.addEventListener('click', (e) => {
            e.preventDefault();

            tu_glide.go('<');
        });
        next_button.addEventListener('click', (e) => {
            e.preventDefault();

            tu_glide.go('>');
        });

        let count = trust_us.querySelectorAll('.glide__slide').length;
        if (window.innerWidth <= 450) {
            if (count <= 1) {
                trust_us.classList.add('static');
            }
        } else if (window.innerWidth >= 451 && window.innerWidth <= 650) {
            if (count <= 2) {
                trust_us.classList.add('static');
            }
        } else if (window.innerWidth >= 651) {
            if (count <= 3) {
                trust_us.classList.add('static');
            }
        }
    }

    let pgcrsl = null;
    let product_gallery = document.querySelector('.js-product-gallery');
    if (product_gallery !== null) {
        pgcrsl = new Glide(product_gallery, {
            type: 'carousel',
            perView: 1,
            autoplay: 5000,
            animationDuration: 500,
            gap: 0,
            //clones: false,
            animationTimingFunc: 'linear',
            //bound: true,
        }).mount();
    }

    var lightbox = new SimpleLightbox({elements: '.js-gallery a'});

    let maps = document.querySelectorAll('.js-world-map');
    if (maps.length > 0) {
        maps.forEach(map => {
            new WorldMap(map);
        });
    }

    let maps_resident = document.querySelectorAll('.js-resident-map');
    if (maps_resident.length > 0) {
        maps_resident.forEach(map => {
            new ResidentMap(map);
        });
    }

    let opinion_lists = document.querySelectorAll('.js-opinions');
    if (opinion_lists !== null) {
        opinion_lists.forEach(op => {
            new Opinions(op);
        });
    }

    let book_lists = document.querySelectorAll('.js-books');
    if (book_lists !== null) {
        book_lists.forEach(list => {
            new Books(list);
        });
    }

    let spec_ranges = document.querySelectorAll('.js-specializations-range');
    if (spec_ranges !== null) {
        spec_ranges.forEach(block => {
            new SpecRange(block);
        });
    }

    let archives =document.querySelectorAll('.js-archive-list');
    if (archives !== null) {
        archives.forEach(ar => {
            new Archive(ar);
        });
    }

    let contact_form = document.querySelector('.js-contact-form');
    if (contact_form !== null) {
        new Form(contact_form, {
            action: '/wp-json/amp/v1/contact_form',
            data: el => {
                return new Promise((resolve, reject) => {
                    let data = {
                        name: '',
                        email: '',
                        phone: '',
                        message: ''
                    };

                    let form_name = el.querySelector('[name="name"]'),
                        form_phone = el.querySelector('[name="phone"]'),
                        form_email = el.querySelector('[name="email"]'),
                        form_message = el.querySelector('[name="message"]');

                    if (form_name !== null) data.name = form_name.value;
                    if (form_phone !== null) data.phone = form_phone.value;
                    if (form_email !== null) data.email = form_email.value;
                    if (form_message !== null) data.message = form_message.value;

                    resolve( data );
                });
            },
            success: (el, response) => {
                if (response.status === 'success') {
                    console.log(response);
                    let status = document.querySelector('[data-element="status"]');
                    let message = status.querySelector('[data-element="success-message"]');
                    status.style.display = 'flex';
                    status.querySelector('[data-element="error-message"]').style.display = 'none';
                    status.classList.add('shown');
                    setTimeout( () => {
                        message.classList.add('shown');
                        el.querySelector('[name="name"]').value = '';
                        el.querySelector('[name="phone"]').value = '';
                        el.querySelector('[name="email"]').value = '';
                        el.querySelector('[name="message"]').value = '';
                        let checkbox = el.querySelector('[name="rules"]');
                        if (checkbox !== null) {
                            if (checkbox.checked) {
                                checkbox.checked = false;
                            }
                        }
                        setTimeout(() => {
                            message.classList.remove('shown');
                            status.classList.remove('shown');
                            status.style.display = 'none';
                        }, 8000);
                    }, 500);
                }
            },
            error: (el, response) => {
                console.log(response);
                let status = document.querySelector('[data-element="status"]');
                let message = status.querySelector('[data-element="error-message"]');

                status.style.display = 'flex';
                status.querySelector('[data-element="success-message"]').style.display = 'none';
                status.classList.add('shown');

                setTimeout( () => {
                    message.classList.add('shown');
                    setTimeout(() => {
                        message.classList.remove('shown');
                        status.classList.remove('shown');
                        status.style.display = 'none';
                    }, 8000);
                }, 500);
            }
        });
    }

    let request_form = document.querySelector('.js-request-form');
    if (request_form !== null) {
        new Form(request_form, {
            action: '/wp-json/amp/v1/request_form',
            data: el => {
                return new Promise((resolve, reject) => {
                    let data = {
                        first_name: '',
                        last_name: '',
                        email: '',
                        phone: '',
                        subject: ''
                    };

                    let form_first_name = el.querySelector('[name="first_name"]'),
                        form_last_name = el.querySelector('[name="last_name"]'),
                        form_phone = el.querySelector('[name="phone"]'),
                        form_email = el.querySelector('[name="email"]'),
                        form_subject = el.querySelector('[name="subject"]');

                    if (form_first_name !== null) data.first_name = form_first_name.value;
                    if (form_last_name !== null) data.last_name = form_last_name.value;
                    if (form_phone !== null) data.phone = form_phone.value;
                    if (form_email !== null) data.email = form_email.value;
                    if (form_subject !== null) data.subject = form_subject.value;

                    resolve( data );
                });
            },
            success: (el, response) => {
                if (response.status === 'success') {
                    console.log(response);
                    let status = document.querySelector('[data-element="status"]');
                    let message = status.querySelector('[data-element="success-message"]');
                    status.style.display = 'flex';
                    status.querySelector('[data-element="error-message"]').style.display = 'none';
                    status.classList.add('shown');
                    setTimeout( () => {
                        message.classList.add('shown');
                        el.querySelector('[name="first_name"]').value = '';
                        el.querySelector('[name="last_name"]').value = '';
                        el.querySelector('[name="phone"]').value = '';
                        el.querySelector('[name="email"]').value = '';
                        el.querySelector('[name="subject"]').value = '';
                        let checkbox = el.querySelector('[name="rules"]');
                        if (checkbox !== null) {
                            if (checkbox.checked) {
                                checkbox.checked = false;
                            }
                        }
                        setTimeout(() => {
                            message.classList.remove('shown');
                            status.classList.remove('shown');
                            status.style.display = 'none';
                        }, 8000);
                    }, 500);
                }
            },
            error: (el, response) => {
                console.log(response);
                let status = document.querySelector('[data-element="status"]');
                let message = status.querySelector('[data-element="error-message"]');

                status.style.display = 'flex';
                status.querySelector('[data-element="success-message"]').style.display = 'none';
                status.classList.add('shown');

                setTimeout( () => {
                    message.classList.add('shown');
                    setTimeout(() => {
                        message.classList.remove('shown');
                        status.classList.remove('shown');
                        status.style.display = 'none';
                    }, 8000);
                }, 500);
            }
        });
    }

    let download_buttons = document.querySelectorAll('.js-download-ebook');
    if (download_buttons !== null) {
        download_buttons.forEach(btn => {
            new Ebook(btn);
        });
    }

    let youtube_players = document.querySelectorAll('.js-video');
    if (youtube_players.length > 0) {
        youtube_players.forEach(player => {
            new YoutubePlayer(player);
        });
    }

    let rp_glide = null;
    let related_podcasts = document.querySelector('.js-related-podcasts');
    if (related_podcasts !== null) {
        let prev_button = related_podcasts.parentNode.querySelector('[data-element="button-prev"]');
        let next_button = related_podcasts.parentNode.querySelector('[data-element="button-next"]');
        rp_glide = new Glide(related_podcasts, {
            type: 'slider',
            perView: 3,
            autoplay: 5000,
            animationDuration: 500,
            gap: 40,
            animationTimingFunc: 'linear',
            bound: true,
            breakpoints: {
                450: {
                    autoplay: 4500,
                    perView: 1,
                },
                650: {
                    autoplay: 4500,
                    perView: 2,
                },
            }
        }).mount();

        prev_button.addEventListener('click', (e) => {
            e.preventDefault();

            rp_glide.go('<');
        });
        next_button.addEventListener('click', (e) => {
            e.preventDefault();

            rp_glide.go('>');
        });

        let count = related_podcasts.querySelectorAll('.glide__slide').length;
        if (window.innerWidth <= 450) {
            if (count <= 1) {
                related_podcasts.classList.add('static');
            }
        } else if (window.innerWidth >= 451 && window.innerWidth <= 650) {
            if (count <= 2) {
                related_podcasts.classList.add('static');
            }
        } else if (window.innerWidth >= 651) {
            if (count <= 3) {
                related_podcasts.classList.add('static');
            }
        }
    }

    let ra_glide = null;
    let related_articles = document.querySelector('.js-related-articles');
    if (related_articles !== null) {
        let prev_button = related_articles.parentNode.querySelector('[data-element="button-prev"]');
        let next_button = related_articles.parentNode.querySelector('[data-element="button-next"]');
        ra_glide = new Glide(related_articles, {
            type: 'slider',
            perView: 3,
            autoplay: 5000,
            animationDuration: 500,
            gap: 40,
            animationTimingFunc: 'linear',
            bound: true,
            breakpoints: {
                450: {
                    autoplay: 4500,
                    perView: 1,
                },
                650: {
                    autoplay: 4500,
                    perView: 2,
                },
            }
        }).mount();

        prev_button.addEventListener('click', (e) => {
            e.preventDefault();

            ra_glide.go('<');
        });
        next_button.addEventListener('click', (e) => {
            e.preventDefault();

            ra_glide.go('>');
        });

        let count = related_articles.querySelectorAll('.glide__slide').length;
        if (window.innerWidth <= 450) {
            if (count <= 1) {
                related_articles.classList.add('static');
            }
        } else if (window.innerWidth >= 451 && window.innerWidth <= 650) {
            if (count <= 2) {
                related_articles.classList.add('static');
            }
        } else if (window.innerWidth >= 651) {
            if (count <= 3) {
                related_articles.classList.add('static');
            }
        }
    }

 let rprod_glide = null;
    let related_products = document.querySelector('.js-related-products');
    if (related_products !== null) {
        let prev_button = related_products.parentNode.querySelector('[data-element="button-prev"]');
        let next_button = related_products.parentNode.querySelector('[data-element="button-next"]');

        let count = related_products.querySelectorAll('.glide__slide').length;
        if (window.innerWidth <= 450) {
            if (count <= 1) {
                related_products.classList.add('static');
            }
        } else if (window.innerWidth >= 451 && window.innerWidth <= 650) {
            if (count <= 2) {
                related_products.classList.add('static');
            }
        } else if (window.innerWidth >= 651) {
            if (count <= 3) {
                related_products.classList.add('static');
            }
        }
        rprod_glide = new Glide(related_products, {
            type: 'carousel',
            perView: 3,
            autoplay: 5000,
            animationDuration: 500,
            gap: 20,
            animationTimingFunc: 'linear',
            bound: true,
            breakpoints: {
                650: {
                    autoplay: 4500,
                    perView: 1,
                },
                991: {
                    autoplay: 4500,
                    perView: 2,
                },
            }
        }).mount();
        //
        // prev_button.addEventListener('click', (e) => {
        //     e.preventDefault();
        //
        //     rprod_glide.go('<');
        // });
        // next_button.addEventListener('click', (e) => {
        //     e.preventDefault();
        //
        //     rprod_glide.go('>');
        // });

    }

    let faq = document.querySelector('.js-faq');
    if (faq !== null) {
        new Faq(faq);
    }

    let podcast_lists = document.querySelectorAll('.js-podcasts');
    if (podcast_lists !== null) {
        podcast_lists.forEach(list => {
            new Podcasts(list);
        });
    }

    let links = document.querySelectorAll('a');
    if (links !== null) {
        links.forEach(link => {
            let href_string = link.getAttribute('href');
            if (href_string !== null) {
                if (href_string.includes('#')) {
                    let element_id = href_string.substring(href_string.indexOf('#') + 1);
                    if (element_id !== '') {
                        let section = document.getElementById(element_id);
                        if (section !== null) {
                            link.addEventListener('click', e => {
                                e.preventDefault();

                                let headerOffset = document.querySelector('.js-header').offsetHeight + 40;
                                let section_position = window.pageYOffset + section.getBoundingClientRect().top;
                                let offsetPosition = section_position - headerOffset + 100;

                                window.scrollTo({
                                    top: offsetPosition,
                                    behavior: 'smooth'
                                });

                                setTimeout(() => {
                                    let interactive_elements = document.querySelectorAll('.interactive-element');

                                    let window_width = window.innerWidth;
                                    let window_height = window.innerHeight;
                                    let window_top = document.body.scrollTop;
                                    let window_bottom = (window_top + window_height);

                                    for (const inter_element of interactive_elements) {
                                        let element_height = inter_element.clientHeight;
                                        let element_top = document.body.scrollTop + inter_element.getBoundingClientRect().top;
                                        let element_bottom = (element_top + element_height) + 100;

                                        if ((element_bottom >= window_top) && element_top <= window_bottom) {
                                            inter_element.classList.add('visible');
                                        }
                                    }
                                }, 100);
                            });
                        }
                    }
                }
            }
        });
    }

    let choose_variant_button = document.querySelector('.js-choose-variant');
    if (choose_variant_button !== null) {
        choose_variant_button.addEventListener('click', (e) => {
            e.preventDefault();

            let href = choose_variant_button.getAttribute('href');
            let element_id = href.substring(href.indexOf('#') + 1);

            let section = document.querySelector('#' + element_id);
            let headerOffset = document.querySelector('.js-header').offsetHeight + 40;
            let section_position = window.pageYOffset + section.getBoundingClientRect().top - 50;
            let offsetPosition = section_position - headerOffset + 100;

            window.scrollTo({
                top: offsetPosition,
                behavior: 'smooth'
            });
        });
    }

    let interactive_specs = document.querySelectorAll('.js-interactive-specs');
    if (interactive_specs !== null) {
        interactive_specs.forEach(spec => {
            new InteractiveSpecs(spec);
        });
    }

    let quantity_inputs = document.querySelectorAll('.js-quantity');
    if (quantity_inputs !== null) {
        quantity_inputs.forEach(qi => {
            new QuantityInput(qi);
        });
    }

    let add_to_cart_buttons = document.querySelectorAll('.js-add-to-cart');
    if (add_to_cart_buttons !== null) {
        add_to_cart_buttons.forEach(btn => {
            btn.addEventListener('click', e => {
                e.preventDefault();

                setTimeout(function() {
                    new UpdateCart();
                }, 1000);
            });
        });
    }

    let fv = document.querySelector('.js-fv-checkbox');
    if (fv !== null) {
        let fields_to_show = fv.closest('.box__content').querySelectorAll('.js-fvat-to-show');
        if (fields_to_show !== null) {
            fields_to_show.forEach(field => {
                field.style.display = 'none';
            });
        }
        let fields_to_hide = fv.closest('.box__content').querySelectorAll('.js-fvat-to-hide');
        if (fields_to_hide !== null) {
            fields_to_hide.forEach(field => {
                let label = field.querySelector('label');
                if (label !== null) {
                    let optional = label.querySelector('span');
                    if (optional !== null) {
                        optional.remove();
                        let abbr = document.createElement('abbr');
                        abbr.classList.add('required');
                        abbr.setAttribute('title', 'wymagane');
                        abbr.innerText = '*';
                        label.appendChild(abbr);
                    }
                }
            });
        }
        fv.addEventListener('change', () => {
            if (fv.checked) {
                fields_to_show.forEach(field => {
                    field.style.display = 'block';

                    if (!field.classList.contains('validate-required')) {
                        field.classList.add('validate-required');
                    }

                    let label = field.querySelector('label');
                    if (label !== null) {
                        let optional = label.querySelector('span');
                        if (optional !== null) {
                            optional.remove();
                            let abbr = document.createElement('abbr');
                            abbr.classList.add('required');
                            abbr.setAttribute('title', 'wymagane');
                            abbr.innerText = '*';
                            label.appendChild(abbr);
                        }
                    }

                    let input = field.querySelector('input');
                    if (input !== null) {
                        let placeholder_str = input.getAttribute('placeholder');
                        if (!placeholder_str.includes('*')) {
                            placeholder_str = placeholder_str + '*';
                        }
                        input.setAttribute('placeholder', placeholder_str);
                    }
                });

                if (fields_to_hide !== null) {
                    fields_to_hide.forEach(field => {
                        if (field.classList.contains('validate-required')) {
                            field.classList.remove('validate-required');
                        }

                        let label = field.querySelector('label');
                        if (label !== null) {
                            let abbr = label.querySelector('abbr');
                            if (abbr !== null) {
                                abbr.remove();
                                let optional = document.createElement('span');
                                optional.classList.add('optional');
                                optional.innerText = '(opcjonalne)';
                                label.appendChild(optional);
                            }
                        }

                        let input = field.querySelector('input');
                        if (input !== null) {
                            let placeholder_str = input.getAttribute('placeholder');
                            placeholder_str = placeholder_str.replace('*', '');
                            input.setAttribute('placeholder', placeholder_str);
                        }
                    });
                }
            } else {
                fields_to_show.forEach(field => {
                    field.style.display = 'none';
                });

                if (fields_to_hide !== null) {
                    fields_to_hide.forEach(field => {
                        if (!field.classList.contains('validate-required')) {
                            field.classList.add('validate-required');
                        }

                        let label = field.querySelector('label');
                        if (label !== null) {
                            let optional = label.querySelector('span');
                            if (optional !== null) {
                                optional.remove();
                                let abbr = document.createElement('abbr');
                                abbr.classList.add('required');
                                abbr.setAttribute('title', 'wymagane');
                                abbr.innerText = '*';
                                label.appendChild(abbr);
                            }
                        }

                        let input = field.querySelector('input');
                        if (input !== null) {
                            let placeholder_str = input.getAttribute('placeholder');
                            placeholder_str = placeholder_str + '*';
                            input.setAttribute('placeholder', placeholder_str);
                        }
                    });
                }
            }
        });
    }

    let pkg_maxi_buttons = document.querySelectorAll('.js-package-maxi');
    if (pkg_maxi_buttons.length > 0) {
        let pkg_maxi_modal = document.querySelector('[data-element="modal-pkg-maxi"]');
        let modalMask = document.querySelector('.modal-mask');
        let contactCloseButtonSuccess = document.querySelector('#closePkgMaxiButton');
        if(contactCloseButtonSuccess !== null) {
            contactCloseButtonSuccess.addEventListener('click', function () {
                pkg_maxi_modal.style.display = 'none';
                modalMask.style.display = 'none';
            });
        }

        modalMask.addEventListener('click', function(){
            pkg_maxi_modal.style.display = 'none';
            modalMask.style.display = 'none';
        });

        pkg_maxi_buttons.forEach((btn) => {
            btn.addEventListener('click', (e) => {
                e.preventDefault();
                pkg_maxi_modal.style.display = 'flex';
                modalMask.style.display = 'block';
            });
        });
    }
});

window.addEventListener('scroll', () => {
    let interactive_elements = document.querySelectorAll('.interactive-element');

    let window_width = window.innerWidth;
    let window_height = window.innerHeight;
    let window_top = document.body.scrollTop - 200;
    if (document.body.clientWidth <= 600) {
        window_top = document.body.scrollTop - 150;
    }
    let window_bottom = (window_top + window_height);

    for (const inter_element of interactive_elements) {
        let element_height = inter_element.clientHeight;
        let element_top = document.body.scrollTop + inter_element.getBoundingClientRect().top;
        let element_bottom = (element_top + element_height) - 500;

        if ((element_bottom >= window_top) && element_top <= window_bottom) {
            inter_element.classList.add('visible');
        }
    }
});
class Books {
    constructor(selector) {
        this.selector = selector;
        this.books = this.selector.querySelectorAll('[data-book]');

        if (this.books.length > 0) {
            this.books.forEach(book => {
                let content = book.getAttribute('data-content');
                let button = book.querySelector('[data-button]');
                let parent = book.parentNode;
                let next_row = book.nextElementSibling;
                let collapse_button = next_row.querySelector('[data-collapse-button]');

                if (button !== null) {
                    button.addEventListener('click', e => {
                       e.preventDefault();
                        let actives = this.selector.querySelectorAll('.books-list__content--active');
                        if(actives.length > 0){
                            actives.forEach(element => {
                                element.querySelector('[data-content]').innerHTML = '';
                                element.classList.remove('books-list__content--active');
                                let bk = element.previousElementSibling;
                                console.log(bk);
                                let pt = element.parentNode;
                                bk.classList.remove('book--shown');
                                // parent.style.borderBottom = '1px solid #7d7d7d';
                                pt.style.paddingBottom = '50px';
                            });
                        }
                        if (next_row !== null) {
                            next_row.querySelector('[data-content]').innerHTML = content;
                            next_row.classList.add('books-list__content--active');
                            book.classList.add('book--shown');
                            // parent.style.borderBottom = 'none';
                            parent.style.paddingBottom = '0px';
                        }
                    });
                }

                if (collapse_button !== null) {
                    collapse_button.addEventListener('click', e => {
                       e.preventDefault();

                        if (book.classList.contains('book--shown')) {
                            book.classList.remove('book--shown');
                            next_row.classList.remove('books-list__content--active');
                            next_row.querySelector('[data-content]').innerHTML = '';
                            // parent.style.borderBottom = 'none';
                            parent.style.paddingBottom = '50px';
                        }
                    });
                }
            });
        }
    }
}
export default Books;
class UpdateCart {
    constructor() {
        this.selectors = document.querySelectorAll('[data-element="cart"]');

        this._getCartData().then(response => {
            if(response !== null){
                if (this.selectors !== null) {
                    this.selectors.forEach(selector => {
                        this.quantity = selector.querySelector('[data-element="quantity"]');

                        if (this.quantity !== null) {
                            this.quantity.innerText = response.data.quantity;
                            console.log(response, selector, this.quantity);
                            if (!selector.classList.contains('mobile-cart--shown')) {
                                selector.classList.add('mobile-cart--shown');
                            }
                        }
                    });
                }
            } else {
                console.log('Wystapił problem podczas próby załadowania danych koszyka');
            }
        }).catch(error => {
            console.log('Wystapił błąd podczas próby załadowania danych koszyka');
        });
    }
    _getCartData() {
        let data = new FormData();
        data.append('action', 'get_cart_data');

        return fetch('http://amp.sh.netivo.pl/wp-admin/admin-ajax.php', {
            method: 'POST',
            cache: 'no-cache',
            credentials: "same-origin",
            redirect: "follow",
            body: data
        }).then(response => {
            if (!response.ok) {
                return response.json().then(function(response) {
                    throw Error(response.code);
                })
            } else {
                return response.json();
            }
        });
    }
}
export default UpdateCart;
class InteractiveSpecs {
    constructor(selector) {
        this.selector = selector;
        this.specializations = this.selector.querySelectorAll('[data-element="spec"]');

        this._languages = window.nt_lang_strings;
        this.current_lang = this._languages.current_lang;

        if (window.innerWidth >= 1200) {
            if (this.specializations !== null) {
                this.specializations.forEach(spec => {
                    let button = spec.querySelector('[data-element="button"]');
                    let index = spec.getAttribute('data-index');
                    if (button !== null) {
                        button.addEventListener('click', e => {
                            e.preventDefault();

                            if (parseInt(index) === parseInt(this.specializations.length)) {
                                let firstChild = this.selector.querySelector('[data-index="1"]');

                                if (spec.classList.contains('blocks-grid__single--active')) {
                                    this._clearAllSpecs();
                                    spec.classList.remove('blocks-grid__single--active');
                                    button.innerText = this._languages.translations.show_more[this.current_lang];
                                    if (firstChild !== null) {
                                        firstChild.classList.remove('blocks-grid__single--hide');
                                    }
                                } else {
                                    this._clearAllSpecs();
                                    spec.classList.add('blocks-grid__single--active');
                                    button.innerText = this._languages.translations.hide[this.current_lang];
                                    if (firstChild !== null) {
                                        firstChild.classList.add('blocks-grid__single--hide');
                                    }
                                }
                            } else {
                                if (spec.classList.contains('blocks-grid__single--active')) {
                                    this._clearAllSpecs();
                                    button.innerText = this._languages.translations.show_more[this.current_lang];
                                } else {
                                    this._clearAllSpecs();
                                    spec.classList.add('blocks-grid__single--active');
                                    button.innerText = this._languages.translations.hide[this.current_lang];
                                }
                            }
                        });
                    }
                });
            }
        }
    }
    _clearAllSpecs() {
        this.specializations.forEach(spec => {
            spec.classList.remove('blocks-grid__single--active');
            spec.classList.remove('blocks-grid__single--hide');
            let button = spec.querySelector('[data-element="button"]');
            button.innerText = this._languages.translations.show_more[this.current_lang];
        });
    }
}
export default InteractiveSpecs;